import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  ScrollRestoration,
  Outlet,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
// All pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import { useDocTitle } from "./components/CustomHook";
import ScrollToTop from "./components/ScrollToTop";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import AssistantEngineer from "./pages/AssistantEngineer";
import PoliceConstable from "./pages/PoliceConstable";
import SubInspector from "./pages/SubInspector";
import NavBar from "./components/Navbar/NavBar";
import Footer from "./components/Footer";
import ComputerCourse from "./pages/ComputerCourse";
import Communication from "./pages/Communicaion";
import Counselling from "./pages/Counselling";
import Placement from "./pages/Placement";
import Project from "./pages/Project";
import { ACCESS_TOKEN } from "./config/Config";
import "./App.css";
import AdminDashboard from "./pages/AdminDashboard";
import { StateContext } from "./contexts/StateContext";
import { useContextSelector } from "use-context-selector";
import Intro from "./components/Intro";
import UserDashboard from "./pages/UserDashboard";
import { AuthProvider } from "./contexts/AuthContext";
function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    };

    window.addEventListener("load", () => {
      aos_init();
    });
  }, []);
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const setRole = useContextSelector(StateContext, (state) => state.setRole);
  const role = useContextSelector(StateContext, (state) => state.role);
  const [userData, setUserData] = useState({});

  useDocTitle("Yaazhtech Education");
  useEffect(() => {
    setAuthenticationStatus();
  }, []);
  function setAuthenticationStatus() {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      let token = localStorage.getItem(ACCESS_TOKEN);
      const base64Url = token?.split(".")[1];
      const base64 = base64Url?.replace("-", "+").replace("_", "/");
      token = base64 ? JSON.parse(window.atob(base64)) : null;
      if (token) {
        //alert("parsed Token" + JSON.stringify(token));
        setUserData(token);
        setIsLoggedIn(true);
      }
    } else {
      setIsLoggedIn(false);
    }
  }
  const Layout = () => {
    return (
      <div>
        <NavBar />
        <ScrollRestoration />
        <Outlet />
        <Footer />
      </div>
    );
  };
  const renderRoutes = (isLoggedIn) => {
    if (isLoggedIn) {
      return (
        <>
          {" "}
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<Intro />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/assistantEngineer" element={<AssistantEngineer />} />
          <Route path="/policeConstable" element={<PoliceConstable />} />
          <Route path="/subInspector" element={<SubInspector />} />
          <Route path="/computerCourse" element={<ComputerCourse />} />
          <Route path="/communication" element={<Communication />} />
          <Route path="/counselling" element={<Counselling />} />
          <Route path="/placement" element={<Placement />} />
          <Route path="/project" element={<Project />} />
          {isLoggedIn && role === "PUPIL" && (
            <Route path="/userDashboard" element={<UserDashboard />} />
          )}
        </>
      );
    } else {
      return (
        <>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<Intro />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/assistantEngineer" element={<AssistantEngineer />} />
          <Route path="/policeConstable" element={<PoliceConstable />} />
          <Route path="/subInspector" element={<SubInspector />} />
          <Route path="/computerCourse" element={<ComputerCourse />} />
          <Route path="/communication" element={<Communication />} />
          <Route path="/counselling" element={<Counselling />} />
          <Route path="/placement" element={<Placement />} />
          <Route path="/project" element={<Project />} />
        </>
      );
    }
  };
  const tokenRoutes = (role,isLoggedIn) => {
    if (role === "ADMIN") {
      return (
        <>
          <Route path="/adminDashboard" element={<AdminDashboard />} />
        </>
      );
    }
    if(role === "PUPIL") {
      return (<>
        {isLoggedIn && role === "PUPIL" && (
          <Route path="/userDashboard" element={<UserDashboard />} />
        )}
        </>
      )
    }
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Layout />}>
        {/* {!isLoggedIn ? (
        <Route path="/" element={<SignIn />} />
      ) : (
        <Route path="/" element={<Home />} />
      )} */}
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<Intro />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/assistantEngineer" element={<AssistantEngineer />} />
          <Route path="/policeConstable" element={<PoliceConstable />} />
          <Route path="/subInspector" element={<SubInspector />} />
          <Route path="/computerCourse" element={<ComputerCourse />} />
          <Route path="/communication" element={<Communication />} />
          <Route path="/counselling" element={<Counselling />} />
          <Route path="/placement" element={<Placement />} />
          <Route path="/project" element={<Project />} />
          {isLoggedIn && role === "PUPIL" && (
          <Route path="/userDashboard" element={<UserDashboard />} />
        )}
         {isLoggedIn && role === "ADMIN" && (
          <Route path="/adminDashboard" element={<AdminDashboard />} />
        )}
          {/* {renderRoutes(isLoggedIn)} */}
          {/* {tokenRoutes(role,isLoggedIn)} */}
          {/* {isLoggedIn && role === "PUPIL" &&<Route path="/userDashboard" element={<UserDashboard />} />}
          {isLoggedIn && role === "ADMIN" &&<Route path="/userDashboard" element={<UserDashboard />} />} */}
          {/* <Route path="/adminDashboard" element={<AdminDashboard />} />
          <Route path="/userDashboard" element={<UserDashboard />} /> */}
        </Route>
      </Route>
    )
  );

  return (
    <div className="font-bodyFont">
      <AuthProvider>
      <RouterProvider router={router} />
      </AuthProvider>
    </div>
  );
}

export default App;
